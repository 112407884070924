export class ApiServiceError extends Error {
  public code: string;
  public data: unknown;

  constructor(code: string, message: string, data: unknown) {
    super(message);
    this.code = code;
    this.data = data;
  }
}

export class BadRequestApiServiceError extends ApiServiceError {
  constructor(message: string, data: unknown) {
    super("400", message, data);
  }
}

export class UnauthorizedApiServiceError extends ApiServiceError {
  constructor(message: string, data: unknown) {
    super("401", message, data);
  }
}

export class CORSApiServiceError extends ApiServiceError {
  constructor(message: string, data: unknown) {
    super("405", message, data);
  }
}
