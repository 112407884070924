import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/campaign/creation",
    beforeEnter: AuthGuard.withCompleteOnboarding,
    meta: { title: "campaign.meta", layout: "AuthenticatedLayout" },
    children: [
      {
        path: "type",
        name: "CampaignCreationType",
        component: () => import("@/modules/campaign/creation/views/CampaignCreationType.vue"),
      },
      {
        path: "settings",
        name: "CampaignCreationSettings",
        component: () => import("@/modules/campaign/creation/views/CampaignCreationSettings.vue"),
      },
      {
        path: "parameters",
        name: "CampaignCreationParameters",
        component: () => import("@/modules/campaign/creation/views/CampaignCreationParameters.vue"),
      },
      {
        path: "summary",
        name: "CampaignCreationSummary",
        component: () => import("@/modules/campaign/creation/views/CampaignCreationSummary.vue"),
      },
      {
        path: "created",
        name: "CampaignCreated",
        component: () => import("@/modules/campaign/creation/views/CampaignCreated.vue"),
      },
    ],
  },
];
