<script lang="ts" setup>
defineOptions({
  name: "KeyFluenzIcon",
});
</script>

<template>
  <svg fill="none" height="72" viewBox="0 0 194 72" width="194" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.6592 28.6433C20.3221 28.6433 22.4808 26.4846 22.4808 23.8216C22.4808 21.1587 20.3221 19 17.6592 19C14.9962 19 12.8375 21.1587 12.8375 23.8216C12.8375 26.4846 14.9962 28.6433 17.6592 28.6433Z"
      fill="#7CAAF1"
    />
    <path
      d="M22.1028 41.8985C22.1028 41.8985 19.4098 38.7868 18.8224 37.8562C18.235 36.9314 18.6945 36.0067 18.6945 36.0067L21.05 32.0516C21.05 32.0516 20.8406 29.9055 17.6592 29.8647C14.4835 29.824 14.1462 32.389 14.1462 32.389L12 52.42H23.1962L22.1028 41.8985Z"
      fill="#7CAAF1"
    />
    <path
      d="M31.9089 19.0059H38.3474L28.3726 35.71C28.3726 35.71 27.5293 36.7627 26.7732 36.9721C26.7732 36.9721 26.0985 37.1815 26.4765 37.8562C26.8546 38.5309 28.9601 41.1365 28.9601 41.1365L33.8864 32.8891C33.8864 32.8891 34.6018 31.5863 35.9919 31.4991C37.3819 31.4176 40.1155 31.4991 40.1155 31.4991L33.5083 42.6546C33.5083 42.6546 32.665 43.4979 31.8275 43.4514H30.9027L38.3474 52.42H32.7929C32.7929 52.42 31.9496 52.42 31.4436 51.8325C30.9376 51.2451 19.8693 37.5247 19.8693 37.5247C19.8693 37.5247 19.2819 37.1466 19.8693 36.1346C20.4568 35.1226 28.8786 21.0241 28.8786 21.0241C28.8786 21.0241 29.7162 19.2152 31.9089 19.0059Z"
      fill="#FFB246"
    />
    <path
      d="M48.1155 47.0281V28.2534H51.6053V47.0281H48.1155ZM51.1981 42.602L51.0353 38.4725L60.853 28.2534H64.7964L56.6188 36.9428L54.6879 39.0599L51.1981 42.602ZM61.2078 47.0281L53.9899 38.4725L56.3222 35.925L65.3082 47.0281H61.2078Z"
      fill="#7CAAF1"
    />
    <path
      d="M73.4626 47.22C71.8689 47.22 70.4789 46.9001 69.2924 46.2661C68.1 45.6322 67.1811 44.7655 66.5297 43.6663C65.8782 42.567 65.5525 41.2991 65.5525 39.8683C65.5525 38.4375 65.8724 37.1696 66.5064 36.0703C67.1404 34.971 68.0186 34.1044 69.1353 33.4705C70.252 32.8365 71.5258 32.5166 72.9566 32.5166C74.3525 32.5166 75.5913 32.8249 76.6848 33.4414C77.7782 34.0579 78.6332 34.9187 79.2613 36.0296C79.8895 37.1405 80.1977 38.455 80.1977 39.973C80.1977 40.1009 80.1919 40.2522 80.1861 40.4441C80.1745 40.6302 80.1628 40.7989 80.1454 40.9385H68.2105V38.7109H78.3773L77.0337 39.4088C77.0512 38.6062 76.8883 37.885 76.5393 37.251C76.1904 36.617 75.7134 36.1227 75.1027 35.7737C74.492 35.4247 73.7766 35.2502 72.9566 35.2502C72.1539 35.2502 71.4327 35.4247 70.7988 35.7737C70.1648 36.1227 69.6762 36.617 69.3389 37.2626C69.0015 37.9082 68.8271 38.6469 68.8271 39.4902V40.0253C68.8271 40.8861 69.019 41.6422 69.4029 42.3053C69.7867 42.9683 70.3393 43.4802 71.0547 43.8466C71.7701 44.213 72.6018 44.3991 73.5498 44.3991C74.3525 44.3991 75.0795 44.2653 75.7251 43.9978C76.3707 43.7303 76.9407 43.3348 77.4409 42.8171L79.2671 44.9109C78.6041 45.6612 77.7898 46.237 76.8127 46.6267C75.8298 47.0222 74.7189 47.22 73.4626 47.22Z"
      fill="#7CAAF1"
    />
    <path
      d="M84.3563 52.4195C83.6409 52.4195 82.9372 52.3032 82.2392 52.0706C81.5413 51.8379 80.9713 51.518 80.5235 51.1051L81.8379 48.6623C82.1753 48.9473 82.5591 49.1799 82.9779 49.3602C83.3967 49.5405 83.8387 49.6278 84.304 49.6278C84.9089 49.6278 85.4033 49.4765 85.7813 49.1741C86.1594 48.8717 86.5083 48.354 86.8282 47.6154L87.6309 45.7891L87.9508 45.3587L93.3424 32.6968H96.5587L89.8527 48.2028C89.4048 49.2555 88.9104 50.0931 88.3637 50.7096C87.817 51.3261 87.2121 51.7623 86.5374 52.024C85.8685 52.2916 85.1415 52.4195 84.3563 52.4195ZM87.2819 47.5397L80.8201 32.7084H84.2807L89.5677 45.0737L87.2819 47.5397Z"
      fill="#7CAAF1"
    />
    <path
      d="M97.152 35.4946V32.8133H106.702V35.4946H97.152ZM99.5134 47.0281V31.9293C99.5134 30.4112 99.9554 29.1957 100.839 28.2941C101.724 27.3926 102.991 26.939 104.637 26.939C105.225 26.939 105.789 27.0029 106.313 27.1251C106.842 27.253 107.284 27.445 107.639 27.7125L106.702 30.2077C106.47 30.0448 106.19 29.911 105.87 29.8064C105.551 29.7017 105.219 29.6435 104.876 29.6435C104.178 29.6435 103.649 29.8354 103.294 30.2193C102.933 30.6032 102.759 31.1732 102.759 31.9235V33.5869L102.84 35.0642V47.0281H99.5134ZM109.087 47.0281V27.1251H112.414V47.0281H109.087Z"
      fill="#7CAAF1"
    />
    <path
      d="M122.877 47.2199C121.661 47.2199 120.585 46.9931 119.661 46.5336C118.73 46.0799 118.003 45.382 117.474 44.4397C116.945 43.5033 116.683 42.3168 116.683 40.886V32.7026H120.062V40.4556C120.062 41.7236 120.359 42.6774 120.946 43.3114C121.534 43.9454 122.359 44.2653 123.412 44.2653C124.197 44.2653 124.889 44.1024 125.477 43.7825C126.064 43.4626 126.524 42.9799 126.844 42.3343C127.164 41.6887 127.326 40.8977 127.326 39.9496V32.7026H130.682V47.028H127.489V43.1369L128.053 44.3467C127.571 45.2598 126.861 45.9636 125.936 46.4638C125 46.9698 123.982 47.2199 122.877 47.2199Z"
      fill="#7CAAF1"
    />
    <path
      d="M141.815 47.22C140.221 47.22 138.831 46.9001 137.644 46.2661C136.452 45.6322 135.533 44.7655 134.882 43.6663C134.23 42.567 133.905 41.2991 133.905 39.8683C133.905 38.4375 134.224 37.1696 134.858 36.0703C135.492 34.971 136.371 34.1044 137.487 33.4705C138.604 32.8365 139.878 32.5166 141.309 32.5166C142.705 32.5166 143.943 32.8249 145.037 33.4414C146.13 34.0579 146.985 34.9187 147.613 36.0296C148.242 37.1405 148.55 38.455 148.55 39.973C148.55 40.1009 148.544 40.2522 148.538 40.4441C148.527 40.6302 148.515 40.7989 148.497 40.9385H136.563V38.7109H146.729L145.386 39.4088C145.403 38.6062 145.24 37.885 144.891 37.251C144.542 36.617 144.066 36.1227 143.455 35.7737C142.844 35.4247 142.129 35.2502 141.309 35.2502C140.506 35.2502 139.785 35.4247 139.151 35.7737C138.517 36.1227 138.028 36.617 137.691 37.2626C137.354 37.9082 137.179 38.6469 137.179 39.4902V40.0253C137.179 40.8861 137.371 41.6422 137.755 42.3053C138.139 42.9683 138.691 43.4802 139.407 43.8466C140.122 44.213 140.954 44.3991 141.902 44.3991C142.705 44.3991 143.432 44.2653 144.077 43.9978C144.723 43.7303 145.293 43.3348 145.793 42.8171L147.619 44.9109C146.956 45.6612 146.142 46.237 145.165 46.6267C144.176 47.0222 143.065 47.22 141.815 47.22Z"
      fill="#7CAAF1"
    />
    <path
      d="M159.891 32.5166C161.055 32.5166 162.078 32.7376 162.962 33.1855C163.846 33.6333 164.556 34.3196 165.079 35.2502C165.609 36.1808 165.87 37.3673 165.87 38.8156V47.0222H162.515V39.2518C162.515 37.9838 162.218 37.0358 161.63 36.4076C161.043 35.7795 160.2 35.4712 159.106 35.4712C158.321 35.4712 157.623 35.6341 157.012 35.954C156.402 36.2739 155.936 36.7566 155.605 37.3906C155.273 38.0246 155.111 38.8272 155.111 39.7927V47.0339H151.789V32.7027H154.983V36.5938L154.418 35.384C154.901 34.4709 155.622 33.7671 156.576 33.2669C157.536 32.7667 158.641 32.5166 159.891 32.5166Z"
      fill="#7CAAF1"
    />
    <path
      d="M168.959 47.028V44.9109L177.782 34.2323L178.428 35.3839H169.122V32.7026H181.086V34.8197L172.263 45.4983L171.594 44.3467H181.33V47.028H168.959Z"
      fill="#7CAAF1"
    />
  </svg>
</template>

<style lang="scss" scoped></style>
