import { CORSApiServiceError, UnauthorizedApiServiceError } from "@/services/ApiServiceError";
import { KzFlashHandler } from "@keyfluenz/vue-core/dist/components/flash/KzFlashHandler";

import type { Router } from "vue-router";
import AuthService from "./AuthService";

export default class ErrorHandler {
  static handle(error: unknown, router: Router): void {
    if (error instanceof UnauthorizedApiServiceError) {
      this.handleUnauthorized(error, router);
    } else if (error instanceof CORSApiServiceError) {
      this.handleCORS(error);
    } /* else if (error instanceof UnauthorizedTwoFactorAuthenticationApiServiceError) {
      this.handleUnauthorizedTwoFactorAuthentication();
    } else if (error instanceof MaintenanceApiServiceError) {
      this.handleMaintenance(error);
    }*/
  }

  private static async handleUnauthorized(error: unknown, router: Router): Promise<void> {
    if (AuthService.isAuthenticated()) {
      AuthService.deleteToken();
    }
    if (router.currentRoute.value.name !== "Login") {
      await router.push({
        name: "Login",
      });
    }
    KzFlashHandler.error("You have been logged out to protect your account, please login again.");
  }

  private static async handleCORS(error: Error): Promise<void> {
    KzFlashHandler.error(`We currently cannot process your request [${error.message}]`);
  }
}
