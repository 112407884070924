import {
  BadRequestApiServiceError,
  CORSApiServiceError,
  UnauthorizedApiServiceError,
} from "@/services/ApiServiceError";
import type { ApolloError } from "@apollo/client";
import type { GraphQLErrors } from "@apollo/client/errors";
import type { ErrorResponse } from "@apollo/client/link/error";
import { KzFlashHandler } from "@keyfluenz/vue-core/dist/components/flash/KzFlashHandler";

export type HttpStatusCodeType = 400 | 401 | 404 | 405 | 500;
export type ResultErrorType = {
  [key: string]: unknown;
};

export type ApiResponseErrorType = {
  statusCode: HttpStatusCodeType;
  result: ResultErrorType;
};

export class ApiErrorHandler {
  static handle(errorResponse: ErrorResponse): void {
    if (!errorResponse.networkError) {
      return;
    }

    const error = errorResponse.networkError as ApiResponseErrorType;

    if (error.statusCode === 401) {
      throw new UnauthorizedApiServiceError("Unauthorized", error.result);
    } else if (error.statusCode === undefined) {
      throw new CORSApiServiceError("CORS", errorResponse.networkError.message);
    }

    throw new BadRequestApiServiceError("BadRequest", error.result);
  }

  //TODO rename this method
  static handle400(
    apolloError: ApolloError,
    callback: (graphQLErrors: GraphQLErrors) => void
  ): void {
    const error = apolloError.networkError as ApiResponseErrorType;

    //TODO move this code outside
    if (apolloError.networkError && error.statusCode === 404) {
      KzFlashHandler.error("We currently cannot process your request [404]");
      return;
    }

    if (apolloError.graphQLErrors) {
      callback(apolloError.graphQLErrors);
    }
  }
}
