<script lang="ts" setup>
import NavigationSidebar from "@/modules/dashboard/components/NavigationSidebar.vue";
import NavigationFooter from "@/modules/creators/components/NavigationFooter.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";

defineOptions({
  name: "AuthenticatedLayout",
});

const route = useRoute();

const css = computed((): string => {
  return route.meta.css as string;
});
</script>

<template>
  <div class="authenticated-layout">
    <NavigationSidebar />
    <main :class="css" class="authenticated-layout-main">
      <div id="test-scroll"></div>
      <RouterView v-slot="{ Component, route }">
        <transition mode="out-in" name="fade">
          <component :is="Component" :key="route.name" />
        </transition>
      </RouterView>
      <NavigationFooter />
    </main>
  </div>
</template>

<style lang="scss" scoped>
.authenticated-layout {
  @apply flex h-screen  relative;

  &-main {
    @apply flex-1 p-8 bg-basic-50 overflow-y-auto;

    &.unpadded-layout {
      @apply p-0;
    }
  }
}
</style>
