import { ApiErrorHandler } from "@/helpers/ApolloErrorHandler";
import ErrorHandler from "@/helpers/ErrorHandler";
import router from "@/router";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { logErrorMessages } from "@vue/apollo-util";

const baseHeaders = {
  "Content-Type": "application/json",
};

if (import.meta.env.VITE_APP_ENVIRONMENT !== "production") {
  Reflect.set(baseHeaders, "Accept", "*/*");
}

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_URL_UNAUTHENTICATED,
  headers: baseHeaders,
});

// Handle errors
const errorLink = onError((error) => {
  if (import.meta.env.VITE_APP_ENVIRONMENT !== "production") {
    logErrorMessages(error);
  }

  if (error.networkError) {
    try {
      ApiErrorHandler.handle(error);
    } catch (error) {
      ErrorHandler.handle(error, router);
    }
  }
});

const cache = new InMemoryCache();
const unAuthenticatedService = new ApolloClient({
  link: errorLink.concat(httpLink),
  cache,
  connectToDevTools: import.meta.env.VITE_APP_ENVIRONMENT === "development",
});

export default unAuthenticatedService;
