import gql from "graphql-tag";

export default class AccountQuery {
  static signup() {
    return gql`
      mutation CompanyCreate($email: String!, $password: String!) {
        CompanyCreate(email: $email, password: $password) {
          message
          status
        }
      }
    `;
  }

  static login() {
    return gql`
      mutation CompanyLogin($email: String!, $password: String!) {
        CompanyLogin(email: $email, password: $password) {
          status
          authorization {
            token
            type
            expires_in
          }
        }
      }
    `;
  }

  static getInfo() {
    return gql`
      query CompanyData {
        CompanyData {
          id
          first_name
          last_name
          email
          role
          has_verified_email
          onboard_position
          phone
          company_name
          wallet {
            balance
          }
        }
      }
    `;
  }

  static onBoardStep1() {
    return gql`
      mutation CompanyOnboardPositionOne(
        $company: String!
        $name: String!
        $lastName: String!
        $phone: String
        $areaCode: String
      ) {
        CompanyOnboardPositionOne(
          company_name: $company
          first_name: $name
          last_name: $lastName
          phone: $phone
          area_code: $areaCode
        ) {
          status
          message
        }
      }
    `;
  }

  static resendVerificationCode() {
    return gql`
      mutation CompanyResendEmailVerificationCode {
        CompanyResendEmailVerificationCode {
          status
          message
        }
      }
    `;
  }

  static verificationCode() {
    return gql`
      mutation CompanyEmailVerificationByCode($code: String!) {
        CompanyEmailVerificationByCode(email_validation_code: $code) {
          status
          message
        }
      }
    `;
  }
}
