<script lang="ts" setup>
defineOptions({
  name: "KFIcon",
});
</script>

<template>
  <svg viewBox="0 0 30 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.59898 9.64327C9.2619 9.64327 11.4206 7.48455 11.4206 4.82164C11.4206 2.15872 9.2619 0 6.59898 0C3.93606 0 1.77734 2.15872 1.77734 4.82164C1.77734 7.48455 3.93606 9.64327 6.59898 9.64327Z"
      fill="white"
    />
    <path
      d="M11.0442 22.8984C11.0442 22.8984 8.35125 19.7867 7.76382 18.8561C7.17638 17.9314 7.63586 17.0066 7.63586 17.0066L9.99143 13.0516C9.99143 13.0516 9.78204 10.9054 6.60058 10.8647C3.42493 10.824 3.08759 13.3889 3.08759 13.3889L0.941406 33.4199H12.1376L11.0442 22.8984Z"
      fill="white"
    />
    <path
      d="M20.8514 0.00579834H27.29L17.3152 16.7099C17.3152 16.7099 16.4718 17.7627 15.7157 17.9721C15.7157 17.9721 15.041 18.1814 15.4191 18.8561C15.7971 19.5308 17.9026 22.1365 17.9026 22.1365L22.8289 13.8891C22.8289 13.8891 23.5443 12.5863 24.9344 12.499C26.3245 12.4176 29.0581 12.499 29.0581 12.499L22.4509 23.6545C22.4509 23.6545 21.6075 24.4978 20.77 24.4513H19.8452L27.29 33.4199H21.7355C21.7355 33.4199 20.8921 33.4199 20.3861 32.8325C19.8801 32.245 8.81186 18.5246 8.81186 18.5246C8.81186 18.5246 8.22443 18.1465 8.81186 17.1345C9.3993 16.1225 17.8212 2.02402 17.8212 2.02402C17.8212 2.02402 18.6587 0.215182 20.8514 0.00579834Z"
      fill="#FFB246"
    />
  </svg>
</template>

<style lang="scss" scoped></style>
