<script lang="ts" setup>
defineOptions({
  name: "XIcon",
});
</script>

<template>
  <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.5997 20.5C5.346 20.5 5.1009 20.4081 4.9096 20.2411C4.4734 19.8604 4.4282 19.1958 4.8089 18.7596L10.5165 12.1029L11.8495 13.7692L6.391 20.1403C6.1917 20.3689 5.9033 20.5 5.5997 20.5ZM12.1504 10.2308L17.6089 3.8597C17.8082 3.6312 18.0967 3.5 18.4003 3.5C18.654 3.5 18.8992 3.592 19.0905 3.759C19.5267 4.1397 19.5718 4.8043 19.1911 5.2405L13.4835 11.8972L12.1504 10.2308Z"
      fill="currentColor"
    />
    <path
      d="M20.3356 19.2815L8.1356 3.7815C7.9933 3.6036 7.7779 3.5 7.55 3.5H4.25C3.9617 3.5 3.6989 3.6652 3.574 3.9251C3.4491 4.185 3.4842 4.4934 3.6643 4.7185L15.8643 20.2185C16.0066 20.3964 16.2221 20.5 16.4499 20.5H19.7499C20.0382 20.5 20.301 20.3348 20.4259 20.0749C20.5508 19.815 20.5157 19.5066 20.3356 19.2815Z"
      fill="currentColor"
    />
  </svg>
</template>

<style scoped></style>
