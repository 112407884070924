<script lang="ts" setup>
defineOptions({
  name: "UnauthenticatedLayout",
});
</script>

<template>
  <div class="unauthenticated-layout">
    <main class="unauthenticated-layout-main">
      <RouterView v-slot="{ Component, route }">
        <transition name="fade" mode="out-in">
          <component :is="Component" :key="route.name" />
        </transition>
      </RouterView>
    </main>
  </div>
</template>

<style lang="scss" scoped>
</style>
