import { useLocalStorage } from "@vueuse/core";

export default class AuthService {
  static isAuthenticated(): boolean {
    return AuthService.getToken() !== "";
  }

  static getToken(): string {
    return useLocalStorage("keyfluenz.token", "").value;
  }

  static setToken(value: string): void {
    const token = useLocalStorage("keyfluenz.token", "");
    token.value = value;
  }

  static deleteToken(): void {
    const token = useLocalStorage("keyfluenz.token", "");
    token.value = null;
  }
}
